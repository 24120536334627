@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

.emoji {
  font-family: "Noto Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Arial Unicode MS", sans-serif;
}

.gradient {
  position: relative;
  background-size: cover;
  background-position: center;
}

.gradient::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.icon {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}

body.no-overflow {
  overflow: hidden;
}

@media (max-width: 600px) {
  #cardImage {
    height: 18rem;
  }
}

.holographic-card {
  transition: all 0.5s ease;
  overflow: hidden;
}

.holographic-card::before {
  content: "";
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    0deg,
    transparent,
    transparent 30%,
    rgba(156, 163, 175, 0.3)
  );
  transform: rotate(-45deg);
  transition: all 0.5s ease;
  opacity: 0;
}

.holographic-card:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(156, 163, 175, 0.5);
}

.holographic-card:hover::before {
  opacity: 1;
  transform: rotate(-45deg) translateY(100%);
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes openHamburger {
  0% {
    transform: translateY(-30rem);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes closeHamburger {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-30rem);
  }
}

.animate-left {
  animation: slideInFromLeft 1s forwards;
}

.animate-right {
  animation: slideInFromRight 1.8s forwards;
}

.animate-up {
  animation: slideInFromUp 1.5s forwards;
}

.open {
  animation: openHamburger 1s forwards;
}

.close {
  animation: closeHamburger 1s forwards;
}

.fade-in {
  opacity: 1;
  transition: all 1s;
}

.card-fade-in {
  opacity: 1;
  transition: all 0.8s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-fade {
  animation-duration: 2.2s;
  animation-fill-mode: both;
  animation-name: fadeIn;
}

.loader {
  border: 4px solid white;
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
}

.mainLoader {
  border: 4px solid #2563eb;
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.scrolled {
  opacity: 0;
  transform: translateY(40px);
  animation: scroll-in 2s forwards;
}

.animate-zoom-in-up {
  animation: zoom-in-up 1s ease-out;
}

@keyframes zoom-in-up {
  0% {
    transform: scale(0.8) translateY(40px);
  }

  100% {
    transform: scale(1) translateY(0);
  }
}

@keyframes scroll-in {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
